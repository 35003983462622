@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

body {
  background: radial-gradient(circle at 50% 50%, #191970, #2b2b73, #483d8b);
  color: white;
  font-family: "Arial", sans-serif;
  overflow: hidden;
  position: relative;
}

input {
  color: white !important;
  background-color: transparent;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.MuiInputBase-input {
  color: white !important;
}

.MuiOutlinedInput-root {
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.5);
}

.game-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.chessboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 50vh;
  margin: 20px 0;
}

.left-sidebar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 20vw;
}

.bottom-bar {
  position: fixed;
  bottom: 20px;
  max-width: 600px;
  background-color: rgba(51, 51, 51, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.sidebar-container {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.emoji-container {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

.floating-emoji {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6xl;
  font-weight: bold;
  color: #ffd700;
  z-index: 30;
  animation: fadeOut 3s forwards;
}

.countdown {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5xl;
  font-weight: bold;
  color: red.500;
}

.circular-gradient {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  border-radius: 50%;
  animation: circularMotion 10s infinite;
}

.bubble {
  position: absolute;
  bottom: -100px;
  background: radial-gradient(circle, #8a2be2, rgba(138, 43, 226, 0));
  border-radius: 50%;
  opacity: 0.7;
  animation: bubbleAnimation 15s infinite;
  z-index: 5;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
    opacity: 0;
  }
}

.score-animation {
  font-family: "Impact", sans-serif; /* Apply Impact font */
  color: #32cd32;
}
